<template>
  <div>
    <img src="../assets/logo.png" mode class="logoApp" />
    <div class="editPhone">
      <div class="flex input">
        <input type="number" v-model="phone" placeholder="请输入新的手机号" />
      </div>
      <div class="flex input row-center">
        <input type="text" v-model="code" placeholder="请输入验证码" />
        <button type="default" :disabled="disabled" @click="getCode">
          {{ code_text }}
        </button>
      </div>
      <div class="button-text" @click="confirm">登录</div>
    </div>
  </div>
  <Overlay :show="show" z-index="99">
    <div class="tip">
      <p class="flex column-end">
        <img
          src="../assets/close.png"
          alt
          class="close"
          @click="show = false"
        />
      </p>
      <div class="text">注册成功</div>
      <div class="text-s">恭喜你，账号注册成功！</div>
      <div class="success" @click="launchMiniProgram">立即登录</div>
    </div>
  </Overlay>
</template>

<script>
import axios from "axios";
import { Apis } from "../utils/apis";
import { post } from "../utils/request";
import { defineComponent, reactive, toRefs } from "vue";
import { Toast, Overlay, Dialog } from "vant";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "",
  props: {},
  components: {
    Overlay,
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      code: "",
      code_text: "获取验证码",
      phone: "",
      disabled: false,
      timer: null,
      online_code: "",
      show: false,
      pid: route.query.id || "",
    });
    function confirm() {
      if (!state.phone) {
        Toast("请输入手机号");
        return;
      }
      if (!state.code) {
        Toast("请输入验证码");
        return;
      }
      if (state.code != state.online_code) {
        Toast("验证码不正确");
        return;
      }
      post(Apis.login, {
        phone: state.phone,
        pid: state.pid,
      }).then((res) => {
        if (res.code == 0) {
          state.show = true;
        } else {
          Toast.fail(res.msg);
        }
      });
    }
    function getCode() {
      if (!state.phone) {
        Toast("请输入手机号");
        return;
      }
      var reg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
      if (!reg.test(state.phone)) {
        Toast("手机号格式不正确");
        return;
      }
      let num = 60;
      state.timer = setInterval(() => {
        if (num < 1) {
          clearInterval(state.timer);
          state.code_text = "获取验证码";
          state.disabled = false;
        } else {
          state.disabled = true;
          num--;
          state.code_text = num + "s";
        }
      }, 1000);
      post(Apis.sendmessage, {
        phone: state.phone,
      }).then((res) => {
        if (res.code == 0) {
          state.online_code = res.msg;
        } else {
          Toast.fail(res.msg);
          Dialog.confirm({
            title: "提示",
            message: "您的账号已注册，请直接登录",
            confirmButtonText: "确定",
            confirmButtonColor: "#305BFE",
            cancelButtonColor: "#305BFE",
          })
            .then(() => {
              launchMiniProgram();
            })
            .catch(() => {
              // on cancel
            });
        }
      });
    }
    function launchMiniProgram() {
      state.show = false;
      axios({
        method: "post",
        url: "https://www.qw5599.com/LS/api/weixinjump",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Access-Control-Allow-Origin": "*",
        },
      }).then(function (res) {
        window.location.href = res.data.msg;
      });
   
      // window.location.href = "weixin://dl/business/?t=jI2cWPcj9zk";
    }
    return {
      ...toRefs(state),
      getCode,
      confirm,
      launchMiniProgram,
    };
  },
});
</script>

<style scoped>
.lv {
  width: 250px;
  height: 250px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
}

.tip {
  width: 271px;
  height: 285px;
  background: url(../assets/bg.png) no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.tip > p {
  padding: 12px;
}
.tip > p > img {
  width: 22px;
  height: 22px;
}
.tip .text {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #494949;
  margin: 90px 0 16px;
  text-align: center;
}
.tip .text-s {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8e8e8e;
  text-align: center;
}
.tip .success {
  width: 214px;
  height: 42px;
  line-height: 42px;
  background: #305bfe;
  border-radius: 21px;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  margin: 24px auto 0;
}

.editPhone {
  padding: 0 26px;
}
.editPhone .input {
  margin: 12px 0 0;
  padding: 13px 0 13px 5px;
  border-bottom: 1px solid #f1f1f1;
}
.editPhone .input input {
  flex: 1;
  font-size: 14px;
}
.editPhone .input input,
.editPhone .input button {
  outline: none;
  background: none;
  border: none;
}
.editPhone .input button {
  font-size: 14px;
  font-weight: 400;
  color: #305bfe;
}
.editPhone .button-text {
  width: 323px;
  height: 44px;
  background: #305bfe;
  border-radius: 4px;
  margin: 45px auto 0;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 44px;
  text-align: center;
}

.logoApp {
  width: 160px;
  height: 160px;
  margin: 100px auto 80px;
}
</style>
